
var filterCards = {

  _initialize: function () { 
    this.cardsFilter();
    this.closingDropdownEvents();
  },

  cardsFilter: function () {
    var self = this;
    var selectFilterButtonLevel = document.querySelector(".filter-select-list-level");
    var selectLevelList = document.querySelector(".filter-list-level");
    var selectLevelListOption = document.querySelectorAll(".filter-select-item-level");
    var selectLevelChooseOption = document.querySelector(".level-choose");
    var selectFilterButtonCategory = document.querySelector(".filter-select-list-category");
    var selectCategoryList = document.querySelector(".filter-list-category");
    var selectCategoryListOption = document.querySelectorAll(".filter-select-item-category");
    var selectCategoryChooseOption = document.querySelector(".category-choose");

    if( !$('.filter-header-select').length ) {
      return;
    }

    if(selectLevelChooseOption.hasAttribute("data-category-id")){
      selectLevelChooseOption.setAttribute("data-category-id", levelId);
    }else{
      selectLevelChooseOption.setAttribute("data-category-id", "");
    }

    if(selectCategoryChooseOption.hasAttribute("data-category-id")){
      selectCategoryChooseOption.setAttribute("data-category-id", levelId);
    }else{
      selectCategoryChooseOption.setAttribute("data-category-id", "");
    }

    selectFilterButtonLevel.addEventListener("click", function(){
      selectLevelList.classList.toggle("visibleFilterList");

    });

    selectLevelListOption.forEach(function (item) {
      var select = item.parentElement;
      select.addEventListener("click", function(){

        var levelId = item.dataset.levelId;
        selectLevelChooseOption.innerHTML = item.innerHTML;
        // if(selectLevelChooseOption.hasAttribute("data-category-id")){
        //
        //   selectLevelChooseOption.setAttribute("data-category-id", levelId);
        // }else{
        //   selectLevelChooseOption.setAttribute("data-category-id", "levelId");
        //
        // }
        selectLevelChooseOption.dataset.categoryId = levelId;
        posts.onCategoryChange();
        self.setFilterQueryParam();
      });
    });


    selectFilterButtonCategory.addEventListener("click", function(){
      selectCategoryList.classList.toggle("visibleFilterList");
    });

    selectCategoryListOption.forEach(function (item) {
      var select = item.parentElement;
      select.addEventListener("click", function(){
        var categoryId = item.dataset.categoryId;
        selectCategoryChooseOption.innerHTML = item.innerHTML;
        selectCategoryChooseOption.dataset.categoryId = categoryId;
        posts.onCategoryChange();
        self.setFilterQueryParam();
        });
      });
    },

  setFilterQueryParam: function() {

    var paramArray = [];

      $('.level-choose, .category-choose').each(function (index, elem) {
        var paramElements = elem.getAttribute('data-category-id');
        paramArray.push(paramElements);
      });

    var param = paramArray.join(',');
      this.setURLParameter('categories', param);
  },

  setURLParameter: function(key,value){
    if (history.pushState) {
      var params = new URLSearchParams(location.search);
      params.set(key, value);
      var newUrl = window.location.protocol + "//" + window.location.host + window.location.pathname + '?' + params.toString();
      window.history.pushState({path:newUrl},'',newUrl);
    }
  },

  closingDropdownEvents() {
    var categoryParent = document.querySelector(".filter-list-category");
    var levelParent = document.querySelector(".filter-list-level");


    window.addEventListener('click', function (e) {
      if (!e.target.closest(".filter-select-list-level")) {
        levelParent.classList.remove('visibleFilterList');
      }
      if (!e.target.closest(".filter-select-list-category")) {
        categoryParent.classList.remove('visibleFilterList');
      }
    });
    // window.addEventListener('scroll', function ( e ) {
    //   if (levelParent.classList.contains("visibleFilterList")) {
    //     levelParent.classList.remove("visibleFilterList");
    //   } else if (categoryParent.classList.contains("visibleFilterList")) {
    //     categoryParent.classList.remove("visibleFilterList");
    //   }
    // })
  }
};

$(document).ready(function () {
  filterCards._initialize();
});
